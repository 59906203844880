import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import * as echarts from 'echarts';
import { xueQingActiveClassOptions, xueQingScoreRange, xueQingUpline } from "@/api/api";
export default {
  name: "xqLineChart",
  data() {
    return {
      active_id: null,
      active_name: null,
      totalScore: null,
      curTab: 'upline',
      // scoreRange
      tabOptions: [{
        label: '上线率统计',
        value: 'upline'
      }, {
        label: '分数段统计',
        value: 'scoreRange'
      }],
      classId: '',
      curClass: null,
      //
      maxUpline: 10,
      upline_scores: [],
      //
      score_left: 0,
      score_right: 750,
      score_length: 100,
      stepOptions: [],
      //
      classOptions: []
    };
  },
  mounted() {
    const {
      active_id
    } = this.$root.useRoute.params;
    const {
      type,
      class_id,
      total,
      name
    } = this.$root.useRoute.query;
    if (type) this.curTab = type;
    if (active_id > 0) {
      this.totalScore = Number(total);
      this.score_right = Number(total);
      this.active_name = name;
      this.active_id = active_id;
      this.classId = class_id > 0 ? Number(class_id) : null;
      this.getClassOptions();
      this.getData();
    } else {
      this.$root.useRouter.back();
    }
  },
  methods: {
    getData() {
      if (this.curTab === 'upline') {
        this.getUpline();
      }
      if (this.curTab === 'scoreRange') {
        this.getRangeVal();
        this.getRange();
      }
    },
    getClassOptions() {
      xueQingActiveClassOptions(this.active_id).then(res => {
        this.classOptions = (res === null || res === void 0 ? void 0 : res.data) || [];
      });
    },
    ClassChange() {
      this.curClass = this.classOptions.find(item => item.id == this.classId);
      this.getData();
    },
    // 上线率
    getUpline() {
      this.upline_scores = Array.from(new Set(this.upline_scores));
      xueQingUpline(this.active_id, {
        upline_scores: this.upline_scores,
        class_id: this.classId || ""
      }).then(res => {
        if (!res.data) return;
        this.upline_scores = res.data.scores;
        let yData = [],
          xData = [];
        res.data.list.map(item => {
          yData.push(Number(item.upline_rate));
        });
        this.upline_scores.map((item, index) => {
          xData.push(`分数线${1 + index}`);
        });
        this.$nextTick(() => {
          this.initChart(xData, yData, {
            min: 0,
            interval: 10,
            max: 100
          });
        });
      });
    },
    handleAddUpline() {
      if (this.upline_scores.length >= 10) return;
      for (let item of this.upline_scores) {
        if (String(item).trim().length < 1) {
          this.$message.error('请完善分数线后再添加');
          return;
        }
        if (isNaN(Number(item))) {
          this.$message.error('分数填写错误');
          return;
        }
      }
      this.upline_scores.push('');
    },
    handleDeleteUpline() {
      if (this.upline_scores.length <= 1) return;
      this.upline_scores.pop();
      this.getUpline();
    },
    //
    getRange() {
      xueQingScoreRange(this.active_id, {
        score_left: this.score_left,
        score_right: this.score_right,
        score_length: this.score_length,
        class_id: this.classId || ''
      }).then(res => {
        if (!res.data) return;
        let xData = [],
          yData = [];
        res.data.list.map(item => {
          xData.push(`${item.left}-${item.right}分`);
          yData.push(Number(item.student));
        });
        this.$nextTick(() => {
          this.initChart(xData, yData);
        });
      });
    },
    scoreChange() {
      if (isNaN(Number(this.score_left)) || isNaN(Number(this.score_right))) {
        this.$message.error(`起止分数错误`);
        this.score_left = 0;
        this.score_right = this.totalScore;
        return;
      }
      if (this.score_right > this.totalScore || this.score_left < 0) {
        this.score_left = 0;
        this.score_right = this.totalScore;
        this.$message.error(`起止分数范围是0-${this.totalScore}`);
      }
      this.getRangeVal();
    },
    getRangeVal() {
      if (this.score_right - this.score_left >= 400) {
        this.stepOptions = [100, 150, 200];
      } else if (this.score_right - this.score_left >= 300) {
        this.stepOptions = [50, 100, 150, 200];
      } else if (this.score_right - this.score_left >= 150) {
        this.stepOptions = [40, 60, 80, 100];
      } else if (this.score_right - this.score_left >= 100) {
        this.stepOptions = [20, 50, 100];
      } else {
        this.stepOptions = [10, 20, 30, 40, 50];
      }
      this.score_length = this.stepOptions[0];
    },
    //
    initChart(xData, yData, yOptions = {}) {
      console.log('x,y', xData, yData);
      let chartDom = document.getElementById('LineChart');
      let myChart = echarts.init(chartDom);
      myChart.clear();
      let option = {
        title: {
          text: this.curTab === 'scoreRange' ? '分数段人数统计' : '上线率'
        },
        tooltip: {
          trigger: 'axis',
          formatter: name => {
            return `<div style="opacity: 0.95;font-size: 14px;" >
                               <div style="font-weight: bolder;padding-bottom: 5px;">${name[0].seriesName}</div>
                               <div >${name[0].name} ${name[0].value}${this.curTab == 'upline' ? '%' : '人'}</div>
                       </div>`;
          }
        },
        grid: {
          left: 20,
          right: 50,
          top: 50,
          bottom: 30,
          containLabel: true
        },
        legend: {
          show: true,
          data: [this.curClass ? this.curClass.name : this.active_name]
        },
        color: ['#0D6CE4'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['(0,100)', '(100,200)', '(200,300)', '(300,400)', '(400,500)', '(500,600)', '(600,700)']
          data: xData
        },
        yAxis: {
          type: 'value',
          ...yOptions
        },
        series: [{
          name: this.curClass ? this.curClass.name : this.active_name,
          type: 'line',
          // data: [150, 230, 224, 218, 135, 147, 260],
          data: yData
        }]
      };
      myChart.setOption(option);
    }
  }
};